import axiosInstance from "config/instance";

const KEYCLOAK_URL =
  process.env.REACT_APP_KEYCLOAK_URL || "https://keycloak.production.x-hub.net";

const Logout = () => {
  localStorage.clear();
  delete axiosInstance.defaults.headers.common.Authorization;
  const loginUrl = window.location.origin.concat("/");

  window.location.href = KEYCLOAK_URL.concat(
    "/protocol/openid-connect/logout"
  ).concat("?redirect_uri=", loginUrl);
};

export default Logout;
